<template>
  <div>
    <AppHeader />
    <div class="bg-white">
      <div class="flex items-center space-x-4 w-full h-full">
        <div class="mt-3 flex w-full items-center">
          <form method="get" :action="`/${locale}/search`" class="[ pl-5 pr-1 ] w-full flex items-center">
            <TextInput
              id="search-dialog"
              class="border-none text-black"
              type="text"
              name="q"
              autocomplete="off"
              :placeholder="$t('searchProducts')"
              :aria-label="$t('searchProducts')"
              icon="search"
              filled
              rounded
              dark
              full-border
              light
              autofocus
            >
              <template #icon>
                <svg-icon-search
                  class="text-primary-1-100 [ absolute left-3 z-10 ] underline"
                  :class="{
                    'fill-current': searchByProduct,
                  }"
                  width="21"
                  height="21"
                />
              </template>
            </TextInput>

            <button
              type="button"
              aria-label="clear search words "
              class="underline ml-1 h-5 w-5 [ flex items-center justify-center ]"
              @click="
                () => {
                  searchBy = '';
                  toggleDefinedAlias('searchDialog');
                }
              "
            >
              <svg-icon-close class="text-primary-1-100 w-3 h-3" />
            </button>

            <button type="submit" class="hidden" />
          </form>
        </div>
      </div>
      <div class="dialog-slider z-40 fixed top-27 h-full left-0 w-full flex flex-col bg-white lg:hidden overflow-auto">
        <LoadingSearchDialog v-if="isFetching" class="mt-5 px-5" />
        <template v-else>
          <section
            v-if="categoriesResult && categoriesResult.length"
            class="mt-3 px-5 pt-5 pb-5 border-b-4 border-primary-1-05"
          >
            <header class="mb-2">
              <h1 class="text-primary-1-100 text-md font-bold">
                {{ $t('suggestedCategories') }}
              </h1>
            </header>
            <div>
              <ul
                v-if="categoriesResult && categoriesResult.length"
                data-attr="searchResult__list"
                class="mt-4 flex flex-wrap gap-4 text-primary-1-100 text-sm"
              >
                <li v-for="(item, index) of categoriesResult" :key="index" class="flex flex-col text-center">
                  <AppLink :to="`/${item.url_path}`" class="border border-primary-1-15 px-4 py-2">
                    <span class="flex-1 flex flex-col items-start">
                      <p v-purify="item.name" class="product-name text-left"></p>
                    </span>
                  </AppLink>
                </li>
              </ul>
            </div>
          </section>

          <!-- <section v-if="brands && brands.length" class="px-5 pt-5 pb-5">
            <header class="mb-2">
              <h1 class="text-primary-1-100 font-body text-lg capitalize font-semibold">{{ $t('suggestedBrands') }}</h1>
            </header>
            <div>
              <ul
                v-if="brands && brands.length"
                data-attr="searchResult__list"
                class="search-suggestions__list text-primary-1-100"
              >
                <li v-for="(brand, index) of brands" :key="index" class="flex flex-col text-center">
                  <AppLink
                    :to="`/brands/${brand.url_key}`"
                    class="w-full flex flex-col items-center justify-between rounded-md px-3 py-2 uppercase"
                    :class="
                      gradientClasses[
                        ((index % gradientClasses.length) + gradientClasses.length) % gradientClasses.length
                      ]
                    "
                  >
                    <span class="flex-1 flex flex-col items-start">
                      <p v-purify="brand.name" class="product-name text-left"></p>
                    </span>
                  </AppLink>
                </li>
              </ul>
            </div>
          </section> -->

          <section v-if="productsResult && productsResult.length" class="px-5 pt-5 pb-5">
            <header class="mb-2">
              <h1 class="text-primary-1-100 text-md font-bold">
                {{ $t('suggestedProducts') }}
              </h1>
            </header>
            <div>
              <ul
                v-if="productsResult && productsResult.length"
                class="search-result text-primary-1-100 flex flex-col mb-20"
              >
                <li v-for="(item, index) of productsResult" :key="index" class="flex mt-3 pb-5">
                  <AppLink :to="`/${item.slug}`" class="flex" @click="toggleDefinedAlias('searchDialog')">
                    <div class="[ bg-white ]">
                      <img v-if="item.thumb" :src="item.thumb.src" class="object-contain" :alt="item.thumb.alt" />
                    </div>
                    <span class="flex flex-col items-start justify-center ml-5">
                      <p v-purify="item.name" class="product-name text-left"></p>

                      <Money :value="item.price" class="text-primary-1-100" />
                    </span>
                  </AppLink>
                </li>
              </ul>
            </div>
          </section>

          <section v-if="noResults" class="px-6 mt-10">
            <svg-icon-not-found-search width="40" height="40" class="fill-current text-primary-1-15 mx-auto" />
            <h2 class="mt-10 text-primary-1-100 font-semibold text-xl text-center font-body mb-3">
              {{ $t('noSearchFor', { string: confirmedSearchWord }) }}
            </h2>
            <p class="flex-1 text-sm text-black">
              <span v-if="locale === 'en'" class="font-bold">{{ $t('enhanceNoteFirstLine') }}</span>
              <span>{{ $t('enhanceNoteSecondLine') }}</span>
            </p>
          </section>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { object, string } from 'yup';

useForm({
  validationSchema: toTypedSchema(
    object({
      q: string(),
    })
  ),
});

const { value } = useField<string>('q');

const { toggleDefinedAlias } = useBottomSheet();

const {
  execute,
  searchBy: searchByProduct,
  result: productsResult,
  isFetching,
  categoriesResult,
  confirmedSearchWord,
  // fetchBrands,
  // brands,
  // isFetchingBrands,
} = useSearchProduct('');

watch(value, newValue => {
  searchByProduct.value = newValue;
});
const searchBy = ref('');

const isTouched = ref(false);

const route = useRoute();

const { t: $t, locale } = useI18n({
  useScope: 'local',
});

const isOpen = ref(false);

watchEffect(() => {
  if (process.server) {
    return;
  }

  window.document.body.classList.toggle('overflow-hidden', isOpen.value);
});

watch(searchBy, value => {
  if (!value) {
    isTouched.value = false;
  }
  searchByProduct.value = value;
});

watch(isOpen, (value, oldValue) => {
  if (value && !oldValue) {
    execute();
    fetchBrands();
  }
});

watch([categoriesResult, productsResult], ([newCategoryResult, newProductResult]) => {
  if (newCategoryResult || newProductResult) {
    isTouched.value = true;
  }
});

onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

function close() {
  isOpen.value = false;
}

// close menu when navigating
watch(route, () => {
  searchBy.value = '';
  toggleDefinedAlias('searchDialog');
  close();
});

onMounted(() => {
  searchBy.value = route?.query?.q?.toString() || '';
  window.document.body.classList.toggle('overflow-hidden', true);
  document.getElementById('search-dialog')?.focus();
});

const noResults = computed(() => {
  return (
    isTouched.value &&
    !!searchByProduct.value &&
    // isFetchingBrands.value ||
    !(isFetching.value || productsResult.value?.length || categoriesResult.value?.length)
    // brands.value.length
  );
});
</script>

<style lang="postcss" scoped>
:deep(input::placeholder) {
  @apply text-primary-1-100;
}

.common-search-list a {
  @apply flex justify-between items-center;
}

.search-result {
  & li {
    @apply py-5 flex items-center;

    a > div > img {
      width: 60px;
      height: 87px;
    }
  }

  & li:not(:last-child) {
    @apply pb-4 border-b border-primary-1-15 border-opacity-50;
  }
}

.search-suggestions__list {
  @apply flex items-center flex-wrap gap-3;
}

.dialog-slider {
  max-height: 100vh;
}

.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
<i18n>
{
  "en": {
    "searchProducts": "Search products",
    "suggestedProducts": "Search Suggestions",
    "suggestedCategories": "Categories",
    "suggestedBrands": "Brands",
    "noSearchFor": "No Search Results for “{string}” ",
    "noSearchDescription": "Double-check your spelling, use more generic terms, try different keywords & you can refine your search later",
    "enhanceResults": "To Enhance your search",
    "enhanceNoteFirstLine": "To enhance the accuracy of your search query, ",
    "enhanceNoteSecondLine": "it is recommended to verify the spelling of all included words and to experiment with various keywords"
  },
  "ar": {
    "searchProducts": "البحث عن المنتجات",
    "suggestedProducts": "اقتراحات البحث",
    "suggestedCategories": "الاقسام",
    "suggestedBrands": "الماركات",
    "noSearchFor": "لا توجد نتائج ل “{string}” ",
    "noSearchDescription": "تحقق جيدًا من الإملاء ، واستخدم مصطلحات عامة ، وجرب كلمات رئيسية مختلفة ، ويمكنك تحسين البحث لاحقًا",
    "enhanceResults": "لتحسين البحث",
    "enhanceNoteSecondLine": "تأكد من الكلمات المستخدمة في البحث أو استخدم عبارات أخرى أو حاول بحث بعبارات أخرى للحصول على المحتوى المطلوب"
  }
}
</i18n>
