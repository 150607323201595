<template>
  <div class="bg-primary-2-30 px-5 h-10 flex items-center">
    <p class="container mx-auto text-tiny text-center uppercase">{{ $t('notice') }}</p>
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<i18n>
{
  "en": {
    "loginOrRegister": "Login or Register",
    "contactUs": "Contact us",
    "switchLang": "عربي",
    "logout":  "Logout",
    "notice": "FREE shipping on orders above 750 EGP",
    "customerSupport": "Customer Support",
    "greet": "Hello, {name}"
  },
  "ar": {
    "contactUs": "اتصل بنا",
    "switchLang": "English",
    "logout":  "تسجيل الخروج",
    "notice": "شحن مجاني للطلبات التي تتخطى ٧٥٠ جنيه",
    "customerSupport": "دعم العملاء",
    "loginOrRegister": "تسجيل الدخول",
    "greet": "مرحبا، {name}"
  }
}
</i18n>
